// @import "~ionic-selectable/esm5/ionic-selectable.component.min.css";

app-home.ion-page {
  margin-top: -10px;
}

hr {
  width: 100%;
}

ion-header {
  margin-top: auto;
  text-align: center;
}

ion-menu {
  width: 100% !important;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    width: 668px;
  }
  @media screen and (min-width: 1024px) {
    width: 1024px;
  }
}
.menu-icon {
  color: #008ea1;
}

bf-btn:hover {
  background-color: #fff !important;
  color: #008ea1 !important;
}

.titre {
  margin-top: -30px;
  padding: 1% 5%;
}

.bordure {
  margin-bottom: 20px;
}

.icon_user {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  /* padding: 0% 5%;
  border-radius: 50%; */
  border: solid 3px white;
  padding: 5px;
  border-radius: 100%;
  /* background-color: lightblue; */
  background: white;
  color: #008ea1;
}

.alert-message {
  overflow-y: scroll;
  overflow-y: auto;
}

#profile-bg {
  position: fixed;
  /* left: 0;
  top: 44px;
  width: 100%;
  height: 150px;
  background-color: #008ea1;
  background-size: 100%;
  background-position: 50% 20%;

  text-align: center; */
}

.ion {
  --ion-color-primary: #008ea1;
}

#profile-info {
  position: absolute;
  top: -95px;
  width: 100%;
  z-index: 1;
  text-align: center;
}
#profile-name {
  color: #444;
  font-size: 26px;
}

#profile-image {
  display: block;
  border-radius: 100px;
  border: 1px solid #fff;
  width: 100px;
  height: 100px;
  margin: 30px auto 0;
  color: #008ea1;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.7);
}

td.monthview-selected {
  background-color: #008fa0 !important;
  color: #fff !important;
  font-weight: bold !important;
}

table.table.table-bordered.table-fixed.monthview-datetable thead {
  color: #008fa0 !important;
  font-weight: bold !important;
}

.picker-opt.picker-opt-selected,
.picker-prefix,
.picker-suffix {
  color: #008fa0;
}

.picker-button.sc-ion-picker-md,
.picker-button.activated.sc-ion-picker-md {
  color: #008fa0;
}

.my-custom-class {
  --backdrop-opacity: 0.2;
}

.ionic-selectable-inner {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: -10px !important;
}

:host(.button-full:not(.button-round)) .button-native {
  border-radius: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  background-color: #008ea1 !important;
}

ion-col.md.hydrated button.ion-no-margin.padding.cancel {
  width: 100%;
  border: solid;
  border-radius: 15px;
  border-color: teal;
  color: teal;
  background-color: white;
  font-size: 15px;
}

ion-col.md.hydrated button.ion-no-margin.padding.add {
  width: 100%;
  background-color: teal;
  color: white;
  border: solid;
  border-radius: 15px;
}

form.mx-auto.my-40.addform.ng-untouched.ng-pristine.ng-invalid {
  max-width: 500px;
  margin: 35px;
}

form.mx-auto.my-40.addform.ng-untouched.ng-pristine.ng-valid {
  max-width: 500px;
  margin: 35px;
}

form.mx-auto.my-40.addform.ng-dirty.ng-valid {
  max-width: 500px;
  margin: 35px;
}

form.mx-auto.my-40.addform.ng-dirty.ng-invalid.ng-touched {
  max-width: 500px;
  margin: 35px;
}

form.mx-auto.my-40.addform.ng-dirty.ng-valid.ng-touched {
  max-width: 500px;
  margin: 35px;
}

ion-button.md.button.button-clear.ion-activatable.ion-focusable.hydrated {
  background-color: teal;
  color: white;
  border: solid;
  border-radius: 15px;
}

.my-label-title {
  text-transform: uppercase;
  font-weight: 600;
}

.active-item {
  --color: var(--ion-color-light);
  --background: #008ea1 !important;
}

.active-item:focus {
  --color: var(--ion-color-light);
  --background: #008ea1 !important;
}
