// http://ionicframework.com/docs/theming/
@import "~@ionic/angular/css/core.css";
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";
// @import '~ionic-selectable/esm5/ionic-selectable.component.min.css';

/* NGX Datatable CSS */
@import "~assets/styles/ngx-datatable/_index.css";
@import "~assets/styles/ngx-datatable/_dark.css";
@import "~assets/styles/ngx-datatable/_material.css";
@import "~assets/styles/ngx-datatable/_bootstrap.css";
@import "~assets/styles/ngx-datatable/_icons.css";

//TailwindCss directives
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";